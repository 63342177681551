import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../api/api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShareProjectService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getForm() {
    return this.apiService.get('/projectPermission/form').pipe(map(res => res.data))
  }

  createLink(projectId, body) {
    return this.apiService.post(`/share_link/model/publish_project/${projectId}?fullUrl=true`, body).pipe(map(res => res.data))
  }

  shareProject(shareLink) {
    return this.apiService.get(`/share_link/${shareLink}`)
  }

  assignProject(projectId: number, email: string) {
    let body = {
      "data": {
        "userEmail": email,
        "projectId": projectId,
        "role": "admin"
      }
    }
    return this.apiService.post(`/user/project`, body)
  }
}
