import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AlertService} from "../../../core/services";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/core/services/projects/projects.service';
import { ShareProjectService } from 'src/app/core/services/share-project/share-project.service';
import { TranslateService } from '@ngx-translate/core';

type InputSettings = {
  link: string;
  projectId: number;
  onShared: () => void;
};

@Component({
  selector: 'app-link-created-dialog',
  templateUrl: './link-created-dialog.component.html',
  styleUrls: ['./link-created-dialog.component.scss']
})
export class LinkCreatedDialogComponent implements OnInit {

  public inviteForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LinkCreatedDialogComponent>,
    private alertService: AlertService,
    private shareProjectService: ShareProjectService,
    @Inject(MAT_DIALOG_DATA)
    public data: InputSettings,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.inviteForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {}

  copyLink() {
    navigator.clipboard.writeText(this.data.link).then().catch(e => console.log(e));
    this.alertService.openInfoModal('', this.translate.instant('share_project_copy_link_message'))
  }

  sendInvite() {
    this.shareProjectService.assignProject(this.data.projectId, this.inviteForm.value.email)
      .subscribe((res) => {
        this.alertService.openInfoModal('', this.translate.instant('share_project_success_message'))
        this.dialogRef.close();
        if (this.data.onShared) {
          this.data.onShared();
        }
      }, (err)=> {
        this.alertService.openErrorModal('', err?.error?.error?.message || this.translate.instant('share_project_error_message'))
      })
  }
}
