<div class="link-created-dialog">
  <div class="link-created-dialog-header">
    {{'share_project_title' | translate}}
  </div>

  <div class="link-created-dialog-body">
    <h5 class="link-created-dialog-body-title">
      {{ 'share_project_message' | translate }}
    </h5>
    <form class="invite" [formGroup]="inviteForm">
      <input class="email" formControlName="email" type="text" placeholder="Email" />

      <button mat-flat-button color="primary" [disabled]="inviteForm.invalid" (click)="sendInvite()">
        <span>{{ 'share' | translate }}</span>
      </button>
    </form>
    <div class="action-btns">
      <span></span>
      <!-- <span (click)="copyLink()" class="copy-button">{{'copy_link' | translate}}</span> -->

      <button mat-stroked-button color="primary" class="action-btn" (click)="dialogRef.close()">
        <span>{{'cancel' | translate}}</span>
      </button>
    </div>
  </div>
</div>
